
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Component } from 'vue-property-decorator';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { Action, Getter, Mutation, State } from 'vuex-class';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { overWriteVariableValuesWithMeasurements } from '@/utils/installationWizardUtilsFunctions';
import { IDevice } from '@/types/devices.types';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';
import { IBatterySystem, WizardPath } from '@/types/wizards/installationWizard.types';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import { minMaxValidation } from '@/utils/utilsFunctions';

@Component({
  methods: { minMaxValidation },
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class BatteryGridSettings extends WizardComponent {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Mutation('installationWizard/addSystemPropertyValues') addSystemPropertyValues!: (data: { systemType: string; data: any }) => void;
  @Mutation('installationWizard/updateSystemPropertyValues') updateSystemPropertyValues!: (data: { systemType: string; data: any; systemIndex: number }) => void;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystemSystemsProps') handleIncludedSystemsTypesSystemSystemsProps!: (
    { systemName, systemIndex, prop, value }: { systemName: string; systemIndex: number; prop: string; value: any }
  ) => void;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => Promise<IDevice>;

  doesHover = false;
  valid = false;
  saved = false;

  localExternalMeasurementsTypes = [
    {
      name: 'CHINT',
      value: 0,
    },
    {
      name: 'EASTRON',
      value: 1,
    },
  ];

  // Container of all possible variables with init value and variable name
  allVariablesMap: IBatterySystem = {
    size: { value: 0 },
    priority: { value: 1 },
    reserve: { value: 15 },
    switchBackTimeFromEmergencyPower: { value: 300 },
    gridMeasurementViaCHINT: { value: 0 },
    smartMeterType: { value: 1 },
    maximumCharge: { value: 85 },
    updateTime: { value: 15 },
    chargeFromGrid: { value: 0 },
    prioritizedCharging: { value: 25 },
    minimumChargeState: { value: 10 },
  };

  variablesList: string[] = []; // Full list of variables which will be sent
  visibleVariables: string[] = []; // Variables that shown in form

  // Field options for form variables
  get visibleVariablesOptions() {
    return {
      size: { unit: 'A', min: 1, max: 2800, component: 'TextField', rules: [this.rules.required] },
      priority: { unit: '', min: 0, max: 255, component: 'TextField', rules: [this.rules.required, this.rules.noCommas] },
      reserve: { unit: '%', min: 11, max: 100, component: 'TextField', rules: [this.rules.required, this.rules.noCommas] },
      switchBackTimeFromEmergencyPower: { unit: 's', min: 60, max: 300, component: 'TextField', rules: [this.rules.required, this.rules.noCommas] },
      gridMeasurementViaCHINT: { component: 'Checkbox', rules: [] },
      smartMeterType: { component: 'ComboBox', rules: [this.rules.required, this.rules.noCommas], data: this.localExternalMeasurementsTypes },
    };
  }

  get isBatteryDefinitionEmpty() {
    return this.includedSystems.battery.definition.length === 0;
  }

  get batteryIncludedSystemsVariables(): IBatterySystem {
    return this.includedSystems.battery.systems[0];
  }

  get isNoBatteryPath(): boolean {
    const noBatteryPaths = [WizardPath.TENANT_INSTALLATION_NO_BATTERY, WizardPath.TENANT_STRING_INVERTER, WizardPath.STRING_INVERTER];
    return noBatteryPaths.includes(this.wizardState.wizardPath);
  }

  // initialize the list of variables relative to the selected battery type system
  async initVariables() {
    await this.fetchMeasurements(this.$route.params.id);
    const alwaysLoadVariables = ['switchBackTimeFromEmergencyPower'];
    if (this.isDeye) {
      this.variablesList = [
        'size', 'priority', 'reserve', 'switchBackTimeFromEmergencyPower', 'gridMeasurementViaCHINT', 'smartMeterType',
        'maximumCharge', 'updateTime', 'chargeFromGrid', 'prioritizedCharging', 'minimumChargeState',
      ];
      const batteryVariables = ['priority', 'reserve', 'switchBackTimeFromEmergencyPower', 'gridMeasurementViaCHINT', 'smartMeterType'];
      this.visibleVariables = ['size'];
      if (!this.isNoBatteryPath) {
        this.visibleVariables = [...this.visibleVariables, ...batteryVariables];
      }
    } else {
      this.variablesList = [
        'size', 'priority', 'reserve', 'maximumCharge', 'updateTime',
        'chargeFromGrid', 'prioritizedCharging', 'minimumChargeState',
      ];
      let batteryVariables: string[] = [];
      if (this.isSolarmax) {
        batteryVariables = ['priority'];
      } else {
        if (!this.isBatteryDefinitionEmpty) batteryVariables = ['priority', 'reserve'];
        if (this.isBatteryDefinitionEmpty) this.allVariablesMap.priority.value = 0;
      }

      this.visibleVariables = ['size', ...batteryVariables];
    }

    // if wizard was done once. values will be taken from measurements if they are different from default values
    if (this.wasInstallationWizardCompleted) {
      this.loadVariablesFromMeasurements(this.visibleVariables);
      // if grid size was set once by the user we take it from the emsObject
      if (this.emsDevice.data.meta.controllerMappings.grid.components.grid1?.size_in_amps !== undefined) {
        const valueInAmps = this.emsDevice.data.meta.controllerMappings.grid.components.grid1.size_in_amps;
        this.allVariablesMap.size.value = typeof (valueInAmps) === 'string' ? parseFloat(valueInAmps).toFixed(1) : valueInAmps;
        if (this.batteryIncludedSystemsVariables?.size !== undefined && this.batteryIncludedSystemsVariables?.size?.value !== undefined) {
          this.batteryIncludedSystemsVariables.size.value = this.allVariablesMap.size.value;
        }
      }
      if (this.isNoBatteryPath) {
        this.allVariablesMap.priority.value = 0;
        if (this.batteryIncludedSystemsVariables) this.batteryIncludedSystemsVariables.priority.value = 0;
      }
    } else {
      // Load variables from measurements that are in the list of alwaysLoadVariables
      this.loadVariablesFromMeasurements(alwaysLoadVariables);
    }
  }
  loadVariablesFromMeasurements(variables: string[]) {
    let variablesToSend: any = {};
    Object.entries(this.allVariablesMap).forEach(([key, value]) => {
      if (variables.includes(key)) {
        return variablesToSend[key] = value;
      }
    });
    variablesToSend = overWriteVariableValuesWithMeasurements(variablesToSend, this.measurements, 'batteryGridPage');
    Object.keys(variablesToSend).forEach((key) => {
      this.allVariablesMap[key as keyof IBatterySystem]!.value = variablesToSend[key as keyof IBatterySystem].value;
    });
  }
  convertVariablesListToObject(list: string[], container: IBatterySystem) {
    const arr = list.map((variableKey: string) => [
      variableKey,
      container[variableKey as keyof IBatterySystem] || this.allVariablesMap[variableKey as keyof IBatterySystem],
    ]);
    return arr.reduce((acc: any, [key, data]: any) => {
      return { ...acc, [key]: data };
    }, {});
  }
  saveVariablesInStore() {
    const system = this.includedSystems.battery.systems[0];
    const uiUpdateTime = (this.isDeye || this.isSolarmax) ? 30 : 15;
    this.allVariablesMap.updateTime.value = uiUpdateTime;
    // if the variables have been in store,
    // update them with respect to the selected battery system
    if (system) {
      system.updateTime.value = uiUpdateTime;
      this.updateSystemPropertyValues({
        systemType: 'battery',
        data: this.convertVariablesListToObject(this.variablesList, system),
        systemIndex: 0,
      });
    } else {
      this.addSystemPropertyValues({
        systemType: 'battery',
        data: this.convertVariablesListToObject(this.variablesList, this.allVariablesMap),
      });
    }
  }

  async handleBatteryField({ key, value }: { key: string; value: any }) {
    this.handleIncludedSystemsTypesSystemSystemsProps({
      systemName: 'battery', systemIndex: 0, prop: key, value,
    });
    this.saved = false;

    await this.$nextTick();
    (this.$refs.form as any).validate();
  }

  setVariablesToSend(variableName: string, value: number | string = 0, systemIndex = 0) {
    this.variablesToSend.set(variableName + systemIndex, installationWizardVariables(variableName, 'batteryGridPage', value, systemIndex));
  }

  async prepareVariablesToSend(): Promise<void> {
    // Enable House
    this.setVariablesToSend('enableHouse');

    // will contain the original user input in amps
    const gridSizeInAmps = this.batteryIncludedSystemsVariables.size.value as number;

    Object.entries(this.batteryIncludedSystemsVariables).forEach(([key, value]) => {
      this.setVariablesToSend(key, key === 'size' ? this.convertAmpsToKiloWatt(value.value) : value.value);
    });
    if (!this.isNoBatteryPath) await this.saveAmpsValueInEMS(gridSizeInAmps);
  }

  /**
   * Saves the user input in the project meta inside the wizard settings
   */
  async saveAmpsValueInEMS(amps: number) {
    const emsCopy = { ...this.emsDevice };
    emsCopy.data.meta.controllerMappings.grid.components.grid1.size_in_amps = amps;
    if (this.emsDevice.id) await this.updateDevice({ device: emsCopy, skipReport: false });
  }

  /**
   * Converts the user input in amps to kW to send the value to the backend
   */
  convertAmpsToKiloWatt(amps: number | string) {
    const value = typeof (amps) === 'string' ? parseInt(amps, 10) : amps;
    const kW = (value * 692) / 1000;
    return parseFloat(kW.toFixed(1));
  }

  async created() {
    await this.initVariables();
    this.saveVariablesInStore();
    await this.$nextTick();
    (this.$refs.form as any).validate();
  }
}
