
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Component } from 'vue-property-decorator';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { IIncludedSystemsTypes, WizardPath } from '@/types/wizards/installationWizard.types';
import { Getter, Mutation, State } from 'vuex-class';
import { IProject } from '@/types/project.types';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';

@Component({
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class HardwareSpecification extends WizardComponent {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Getter('installationWizard/includedSystemsTypes') includedSystemsTypes!: IIncludedSystemsTypes;
  @Getter('projects/project') project!: IProject;
  @Getter('installationWizard/considerProjectStatus') considerProjectStatus!: boolean;

  doesHover = false;
  get emsModes() {
    return [
      this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.0.title'),
      this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.1.title'),
      this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.2.title'),
      this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.3.title'),
    ];
  }

  get tenantInstallationNoBattery() {
    return this.project.meta.wizardSettings?.wizardPath === WizardPath.TENANT_INSTALLATION_NO_BATTERY;
  }

  setVariablesToSend(variableName: string, value: number | string = 0, systemIndex = 0, page = 'pilotPage') {
    this.variablesToSend.set(variableName + systemIndex, installationWizardVariables(variableName, page, value, systemIndex));
  }

  get isNoBatteryPath(): boolean {
    const noBatteryPaths = [WizardPath.TENANT_INSTALLATION_NO_BATTERY, WizardPath.TENANT_STRING_INVERTER, WizardPath.STRING_INVERTER];
    return noBatteryPaths.includes(this.wizardState.wizardPath);
  }

  get power(): number {
    return (this.measurements[installationWizardVariables('maxPowerInverter', 'componentsPage').variable] as number ?? 0) / this.wizardState.inverterCount;
  }

  sendBatteryVariables() {
    this.setVariablesToSend('isBattery', (this.includedSystemsTypes.battery.definition.length > 0 && !this.isNoBatteryPath) ? 1 : 0, 0, 'componentsPage');
    if (this.power >= 30000) {
      const separateBatteryInput = this.includedSystems.battery.definition.length > 1 ? 1 : 0;
      const oneBatteryInput = this.wizardState.hybridSettings.useBothBatteryInputs ? 1 : 0;
      this.setVariablesToSend('oneBatteryInput', separateBatteryInput === 1 ? 0 : oneBatteryInput, 0, 'componentsPage');
    } else {
      this.setVariablesToSend('oneBatteryInput', 0, 0, 'componentsPage');
    }
  }

  selectedMode: any = this.$t('installationWizard.defineEmsSettings.generalEmsSettings.modes.1.title');
  selectedIndex: any = 1;
  async prepareVariablesToSend(): Promise<void> {
    // Send operation mode
    this.variablesToSend.set('OperationMode', {
      variable: 'prgEMS.fbEMS.eOperationMode',
      value: this.selectedIndex,
    });

    // Enable EMS
    if (!this.tenantInstallationNoBattery) {
      this.variablesToSend.set('EnableEMS', {
        variable: 'prgEMS.fbEMS.bEnable',
        value: 1,
        feedbackVariable: 'prgEMS.fbEMS.bStateEnable',
        isBoolean: true,
      });
    }

    this.sendBatteryVariables();
  }

  handleSelection() {
    this.selectedIndex = this.emsModes.indexOf(this.selectedMode); // Set selected mode before save.
  }

  async created() {
    if (this.wasInstallationWizardCompleted) {
      await this.getVariableValuesFromMeasurements();
    }
  }

  async getVariableValuesFromMeasurements() {
    const mqttOperationMode = await this.measurements['prgEMS.fbEMS.eOperationMode'];
    if (mqttOperationMode) {
      this.selectedIndex = mqttOperationMode;
    }
  }
}
