
import { Component, Prop } from 'vue-property-decorator';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import { Action, Getter, State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';
import { minMaxValidation } from '@/utils/utilsFunctions';
import { IDevice } from '@/types/devices.types';
import devicesTypes from '@/store/modules/devices/settings/devicesTypes';
import {
  plcVersionDate,
  tenantUpdateWagoCounters,
} from '@/utils/versionManagementUtils';
import { IProject } from '@/types/project.types';
import { IMeasurements } from '@/types/measurements.types';

/**
 * Component that represent EMS settings view.
 * Contains navigation tabs. EMS settings and Mode settings.
 */
@Component({
  methods: { minMaxValidation },
  components: {
    ShowEventDotBase,
    InputFieldBase,
    OutputFieldBase,
    DropDownBase,
    Switch2VBase,
    LabelUnitWrapper,
  },
})
export default class GeneralSettingsEMS extends mixins(Validation) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: true }) showMpcBatteryPowerLimit!: boolean;
  @Prop({ default: true }) showPlcControllingState!: boolean;
  @State('app') appState!: IAppState;
  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('projects/project') project!: IProject;
  @Action('devices/transferToRegelenergie') transferToRegelenergie!: (device: IDevice) => void;

  sizeMainFuseDialog = null

  get limitsForFields() {
    const limits: Record<string, { min: number; max: number }> = {};
    const nonSolarmaxSettings = ['max_depth', 'reserve_battery'];
    const keysForObject = [
      'size_main_fuse',
      ...(this.isSolarmax ? [] : nonSolarmaxSettings),
      'reserve_charge',
      'min_charge_battery',
      'update_time',
    ];
    const controllerMappings: any = devicesTypes.EMSV2.controllerMappings;
    keysForObject.forEach((key: string) => {
      limits[key] = controllerMappings[key].minMaxBorders;
    });

    return limits;
  }

  get mappingsList() {
    return this.deviceData?.data?.meta?.controllerMappings;
  }

  get isSuperAdmin() {
    return this.appState.user.super_admin;
  }

  // ems settings
  get textMappingOperation() {
    const lang: any = this.$t('mlModel.EMS.settingsView.mappingOperation');
    return {
      0: lang.allOff,
      1: lang.selfConsumption,
      2: lang.peakLoadCapping,
      3: lang.loadManagement,
    };
  }

  get textMappingMessage() {
    const lang: any = this.$t('mlModel.EMS.settingsView.mappingMessage');
    return {
      0: lang.disabled,
      1: lang.enabled,
      2: lang.allPrioritiesAtMinimum,
      3: lang.allPrioritiesAtMaximum,
      4: lang.missingPowerValuesOnDeviceSetup,
      5: lang.tooManyFunctionsInThisProject,
    };
  }

  get dropDownOperation() {
    return {
      'DropDown_targetValue': this.mappingsList.operation_mode,
    };
  }

  get dropDownMessage() {
    return {
      'DropDown_targetValue': this.mappingsList.message,
    };
  }
  get hasBattery() {
    if (plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) {
      const hasLynusInverter = this.project.meta.hasLynusInverter ?? true;
      // for new projects, check if there is a battery mapped in the EMS or if hasLynusInverter is true via the tenant model
      return hasLynusInverter === true || !!this.mappingsList.battery.components.battery1;
    } else {
      const batteryEnableValue = this.measurements['prgEnergy.fbI.bEnable'] ?? undefined;
      // for old projects, check if battery enable variable is set or if there is a battery mapped in the EMS
      return batteryEnableValue !== undefined || !!this.mappingsList.battery.components.battery1;
    }
  }
  get switch2VEnableBattery() {
    return {
      'Switch2V_onOff': this.mappingsList.battery.components.battery1?.switch_enable ?? 'prgEnergy.fbI.bEnable',
      'Switch2V_state': this.mappingsList.battery.components.battery1?.state_enable ?? 'prgEnergy.fbI.stDataBattInverter.bEnabled',
    };
  }

  get switch2VEnable() {
    return {
      'Switch2V_onOff': this.mappingsList.enable_ems,
      'Switch2V_state': this.mappingsList.state_enable_ems,
    };
  }

  get switch2VAllowChargingFromGrid() {
    return {
      'Switch2V_onOff': this.mappingsList.allow_charging_button,
      'Switch2V_state': this.mappingsList.allow_charging_state,
    };
  }

  get switch2VMainFuseMonitoring() {
    return {
      'Switch2V_onOff': this.mappingsList.activate_main_fuse,
      'Switch2V_state': this.mappingsList.state_main_fuse,
    };
  }

  get outputFieldReserveForEmergency() {
    return {
      'InputField_targetValue': this.mappingsList.reserve_battery,
    };
  }

  get outputFieldReserveChargingFromGrid() {
    return {
      'InputField_targetValue': this.mappingsList.reserve_charge,
    };
  }

  get outputFieldUpdatingTime() {
    return {
      'InputField_targetValue': this.mappingsList.update_time,
    };
  }

  get statusIndicatorControllingState() {
    return {
      'ShowEventDot1_targetValue': this.mappingsList.enable_controlling,
    };
  }

  get switch2VNewBatteryPower() {
    return {
      'OutputField_targetValue': this.mappingsList.calculated_battery_power,
    };
  }

  get sizeMainFuseList() {
    return Object.keys(this.mappingsList.size_main_fuse);
  }

  // size main fuse object
  get sizeMainFuse() {
    return this.mappingsList.size_main_fuse;
  }

  get outputFieldMaxDepth() {
    return {
      'InputField_targetValue': this.mappingsList.max_depth,
    };
  }

  get shouldHaveTransferOption() {
    return this.isSuperAdmin && this.deviceData?.data?.type === 'EMSV2';
  }

  get wasAlreadyTransferred() {
    return this.deviceData?.data?.meta?.regie === true;
  }
}
