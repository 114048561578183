// This File will contain all default Mappings for every Device Type (EMS, EnergyView)

// EMS Functions
import i18n from '@/ui/plugins/i18n';

export function pvMappingEMS(systemIndex: number) {
  return {
    power: 'prgEnergy.lrPVPower',
    error: '',
    title: 'PV Total',
    energy_counter: 'prgEnergy.lrCounterProdPV',
  };
}

export function pvMappingEMSDeye(systemIndex: number, isAcPv: boolean, inverterIndex = 1) {
  return {
    power: !isAcPv ? `prgPVOutData.rPower_${inverterIndex}_${systemIndex}` : 'prgEnergy.lrACPVPower',
    error: '',
    title: !isAcPv ? `MPPT${systemIndex} Inverter ${inverterIndex}` : 'PV AC Total',
    energy_counter: !isAcPv ? `prgPVOutData.lrCounterE_Prod_${inverterIndex}_${systemIndex}` : 'prgEnergy.lrCounterProdACPV',
  };
}

export function stringInverterMappingEMS(systemIndex: number) {
  return {
    power: `prgACPV.rPower_${systemIndex + 1}`,
    error: 'prgACPV.byErrorWarningInv',
    title: `String Inverter ${systemIndex + 1}`,
    energy_counter: `prgACPV.lrCounter_E_P_${systemIndex + 1}`,
  };
}

export function batteryMappingEMS(systemIndex: number) {
  return {
    power: 'prgEnergy.fbI.stDataBattery.lrPower',
    target_power: 'prgEnergy.fbI.stDataBattInverter.rTargetPower',
    state_enable: 'prgEnergy.fbI.stDataBattInverter.bEnabled',
    state_reset: '',
    error: 'prgEnergy.byErrorWarningB1',
    title: i18n.t('installationWizard.defaultMappings.systemNames.battery').toString(),
    soc: 'prgEnergy.fbI.stDataBattery.dwSOC',
    size_capacity: `prgEnergy.lrMaxCapacityBattery${systemIndex + 1}`,
    capacity: 'prgEnergy.fbI.stDataBattery.lrCapacity',
    priority: 'prgEnergy.fbI.byPriority',
    switch_enable: 'prgEnergy.fbI.bEnable',
    switch_reset: '',
    energy_counter: 'prgEnergy.lrCounterProdBatt',
    reverse_energy_counter: 'prgEnergy.lrCounterConsBatt',
  };
}

export function defineSystemIndex(systemIndex: number, systemCount: number) {
  let componentIndex = 0;
  let inverterIndex = 0;
  if (systemCount === 2) {
    componentIndex = systemIndex % 2 === 0 ? 1 : 2;
    inverterIndex = Math.floor(systemIndex / 2) + 1;
  } else {
    componentIndex = 1;
    inverterIndex = systemIndex + 1;
  }
  return {
    componentIndex,
    inverterIndex,
  };
}

export function multiBatteryMappingEMS(systemIndex: number, batteryCount: number) {
  const { componentIndex, inverterIndex } = defineSystemIndex(systemIndex, batteryCount);

  return {
    power: `prgBattOutData.rPower_${inverterIndex}_${componentIndex}`,
    target_power: 'prgEnergy.fbI.stDataBattInverter.rTargetPower',
    state_enable: 'prgEnergy.fbI.stDataBattInverter.bEnabled',
    state_reset: '',
    error: 'prgEnergy.byErrorWarningB1',
    title: `${i18n.t('installationWizard.defaultMappings.systemNames.battery')} ${componentIndex} Inverter ${inverterIndex}`,
    soc: `prgBattOutData.wSOC_${inverterIndex}_${componentIndex}`,
    size_capacity: `prgEnergy.lrMaxCapacityBattery${systemIndex + 1}`,
    capacity: 'prgEnergy.fbI.stDataBattery.lrCapacity',
    priority: 'prgEnergy.fbI.byPriority',
    switch_enable: 'prgEnergy.fbI.bEnable',
    switch_reset: '',
    energy_counter: `prgBattOutData.lrCounterE_Prod_${inverterIndex}_${componentIndex}`,
    reverse_energy_counter: `prgBattOutData.lrCounterE_Cons_${inverterIndex}_${componentIndex}`,
  };
}

export function gridMappingEMS() {
  return {
    power: 'prgEnergy.fbGrid.stDataGrid.lrPower',
    error: '',
    title: i18n.t('installationWizard.defaultMappings.systemNames.grid').toString(),
    size: 'prgEnergy.fbGrid.rSizeGridConn',
    energy_counter: 'prgEnergy.lrCounterProdGrid',
    reverse_energy_counter: 'prgEnergy.lrCounterConsGrid',
    size_in_amps: 0,
    is_on_grid: 'prgEnergy.fbI.bOffGridModeActive',
  };
}

export function houseMappingEMS(systemIndex: number) {
  return {
    power: 'prgEnergy.lrHousePower',
    state_enable: 'prgEnergy.fbHouse.stDataHouseC.bEnabled',
    switch_enable: 'prgEnergy.fbHouse.bEnable',
    error: '',
    title: `${i18n.t('installationWizard.defaultMappings.systemNames.house')} ${systemIndex}`,
    energy_counter: 'prgEnergy.lrCounterConsHouse',
  };
}

export function chargeStationMappingEMS(systemIndex: number, systemType = 'Webasto') {
  return {
    power: `prgCS.lrPowerCS_${systemIndex + 1}`,
    error: `prgCS.byErrorWarning_${systemIndex + 1}`,
    title: `${systemType}`,
    car_connected: `prgCS.bCarConected_${systemIndex + 1}`,
    charging_time: '',
    state_charging_station: '',
    state_emergency: `prgCS.bSOnEmergPowerOff_${systemIndex + 1}`,
    state_manual: '',
    current: '',
    min_power: `prgCS.fbCS${systemIndex + 1}.stDataECS.bySCSMinPower`,
    switch_emergency: `prgCS.bOnEmergPowerOff_${systemIndex + 1}`,
    priority: `prgCS.fbCS${systemIndex + 1}.stSetupECS.byPriority`,
    enable_soc: `prgCS.fbCS${systemIndex + 1}.stSetupECS.byEnableSOC`,
    disable_soc: `prgCS.fbCS${systemIndex + 1}.stSetupECS.byDisableSOC`,
    max_power: `prgCS.fbCS${systemIndex + 1}.stSetupECS.rMaxPower`,
    slider_manual: '',
    slider_target_power: '',
    slider_min_power: `prgCS.fbCS${systemIndex + 1}.stSetupECS.byCSMinPower`,
    target_power: `prgCS.fbCS${systemIndex + 1}.stDataECS.rTargetPower`,
    state_enable: `prgCS.fbCS${systemIndex + 1}.stDataECS.bEnabled`,
    switch_enable: `prgCS.fbCS${systemIndex + 1}.bEnable`,
    switch_manual: '',
    energy_counter: `prgCS.lrCounterCS_${systemIndex + 1}`,
    external_energy_measurement: false,
    calculate_as_external_device: false,
  };
}

export function newChargeStationMappingsEMS(systemIndex: number, systemType = 'Webasto') {
  return {
    power: `prgCS.lrPowerCS_${systemIndex + 1}`,
    error: `prgCS.byErrorWarning_${systemIndex + 1}`,
    title: `${i18n.t('installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.charge_station')} ${systemIndex + 1}: ${systemType}`,
    car_connected: `prgCS.bCarConected_${systemIndex + 1}`,
    charging_time: '',
    state_charging_station: '',
    state_emergency: `prgCS.bSOnEmergPowerOff_${systemIndex + 1}`,
    state_manual: '',
    current: '',
    min_power: `prgCS.bySMinPower_${systemIndex + 1}`,
    switch_emergency: `prgCS.bOnEmergPowerOff_${systemIndex + 1}`,
    priority: `prgCS.byPrio_${systemIndex + 1}`,
    enable_soc: `prgCS.byEnaSOC_${systemIndex + 1}`,
    disable_soc: `prgCS.byDisabSOC_${systemIndex + 1}`,
    max_power: `prgCS.rMaxPower_${systemIndex + 1}`,
    slider_manual: '',
    slider_target_power: '',
    slider_min_power: `prgCS.byMinPower_${systemIndex + 1}`,
    target_power: `prgCS.rTargetPower_${systemIndex + 1}`,
    state_enable: `prgCS.bSEnabled_${systemIndex + 1}`,
    switch_enable: `prgCS.bEnable_${systemIndex + 1}`,
    switch_manual: '',
    energy_counter: `prgCS.lrCounterCS_${systemIndex + 1}`,
    external_energy_measurement: false,
    calculate_as_external_device: false,
  };
}

export function electricHeatingMappingEMS(systemIndex: number, systemType = 'Askoma') {
  const max_power: any = {};
  const max_power_key = `mp-${Date.now()}`;
  max_power[max_power_key] = `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.rMaxPower1`;

  return {
    power: `prgEHE.lrPower_${systemIndex + 1}`,
    error: `prgEHE.byErrorWarning_${systemIndex + 1}`,
    title: `${systemType} ${systemIndex + 1}`,
    temperature: `prgEHE.fbEHE${systemIndex + 1}.stDataEHE.rTempInBoiler`,
    state_electric_heating: '',
    state_emergency: `prgEHE.bSOnEmergPowerOff_${systemIndex + 1}`,
    state_manual: '',
    state_time: `prgEHE.fbEHE${systemIndex + 1}.stDataEHE.bSTimeEnabled`,
    state_disable_protection: `prgEHE.bSDisableLegionellaPr_${systemIndex + 1}`,
    switch_emergency: `prgEHE.bOnEmergPowerOff_${systemIndex + 1}`,
    priority: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.byPriority`,
    enable_soc: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.byEnableSOC`,
    disable_soc: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.byDisableSOC`,
    switch_disable_protection: `prgEHE.bDisableLegionellaPr_${systemIndex + 1}`,
    switch_manual: '',
    slider_target_power: '',
    switch_time: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.bTimeEnabled`,
    hour_on: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.byHourOn`,
    minute_on: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.byMinutesOn`,
    hour_off: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.byHourOff`,
    minute_off: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.byMinutesOff`,
    target_temp_on: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.uiTargetTempOn`,
    target_temp_off: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.uiTargetTempOff`,
    target_temp_max: `prgEHE.fbEHE${systemIndex + 1}.stSetupEHE.uiTargetTempMax`,
    max_power,
    target_power: `prgEHE.fbEHE${systemIndex + 1}.stDataEHE.rTargetPower`,
    state_enable: `prgEHE.fbEHE${systemIndex + 1}.stDataEHE.bEnabled`,
    switch_enable: `prgEHE.fbEHE${systemIndex + 1}.bEnable`,
    slider_manual: '',
    energy_counter: `prgEHE.lrCounterCons_${systemIndex + 1}`,
    external_energy_measurement: false,
  };
}

export function heatingPumpMappingEMS(systemName: string) {
  const max_power: Record<string, string> = {
    [`mp-${Date.now()}`]: 'prgHP.fbHP.stSetupHP.rMaxPower1',
    [`mp-${Date.now() + 1}`]: 'prgHP.fbHP.stSetupHP.rMaxPower2',
  };

  return {
    boiler_temperature: '',
    boiler_water_temperature: '',
    disable_soc: 'prgHP.fbHP.stSetupHP.byDisableSOC',
    enable_soc: 'prgHP.fbHP.stSetupHP.byEnableSOC',
    energy_counter: '',
    error: '',
    flow_temperature: '',
    hour_off: 'prgHP.fbHP.stSetupHP.byHourOff',
    hour_on: 'prgHP.fbHP.stSetupHP.byHourOn',
    inlet_temperature: '',
    manual_power: '',
    max_power,
    minute_off: 'prgHP.fbHP.stSetupHP.byMinutesOff',
    minute_on: 'prgHP.fbHP.stSetupHP.byMinutesOn',
    outlet_temperature: '',
    power: '',
    priority: 'prgHP.fbHP.stSetupHP.byPriority',
    return_temperature: '',
    slider_manual: '',
    slider_power: 'prgHP.fbHP.stSetupHP.byTimeEnabledTargetPower',
    state_emergency: 'prgHP.bSOnEmergPowerOff_1',
    state_enable: 'prgHP.fbHP.stDataHP.bEnabled',
    state_heating_pump: '',
    state_manual: '',
    state_reset: 'prgHP.fbHP.stDataHP.bSReset',
    state_time: 'prgHP.fbHP.stDataHP.bSTimeEnabled',
    state_time_power: 'prgHP.fbHP.stDataHP.bySTimeEnabledTargetPower',
    switch_emergency: 'prgHP.bOnEmergPowerOff_1',
    switch_enable: 'prgHP.fbHP.bEnable',
    switch_manual: '',
    switch_reset: 'prgHP.fbHP.stSetupHP.bReset',
    switch_time: 'prgHP.fbHP.stSetupHP.bTimeEnabled',
    target_power: 'prgHP.fbHP.stDataHP.rTargetPower',
    title: systemName,
  };
}

export function newHeatingPumpMappingsEMS(systemIndex: number, systemName: string) {
  const max_power: Record<string, string> = {
    [`mp-${Date.now()}`]: `prgHP.rMaxPower_${systemIndex + 1}_1`,
    [`mp-${Date.now() + 1}`]: `prgHP.rMaxPower_${systemIndex + 1}_2`,
  };
  return {
    boiler_temperature: `prgHP.rBoilerTempHeatingSys_${systemIndex + 1}`,
    boiler_water_temperature: `prgHP.rBoilerTempServiceWater_${systemIndex + 1}`,
    disable_soc: `prgHP.byDisabSOC_${systemIndex + 1}`,
    enable_soc: `prgHP.byEnaSOC_${systemIndex + 1}`,
    energy_counter: `prgHP.lrCounterEl_HP_${systemIndex + 1}`,
    error: `prgHP.byErrorWarning_${systemIndex + 1}`,
    flow_temperature: `prgHP.rFlowTemp_${systemIndex + 1}`,
    hour_off: `prgHP.byHourOff_${systemIndex + 1}`,
    hour_on: `prgHP.byHourOn_${systemIndex + 1}`,
    inlet_temperature: `prgHP.rFlowTempEnergySource_${systemIndex + 1}`,
    manual_power: '',
    max_power,
    minute_off: `prgHP.byMinuteOff_${systemIndex + 1}`,
    minute_on: `prgHP.byMinuteOn_${systemIndex + 1}`,
    outlet_temperature: `prgHP.rReturnTempEnergySource_${systemIndex + 1}`,
    power: `prgHP.lrPowerHP_${systemIndex + 1}`,
    priority: `prgHP.byPrio_${systemIndex + 1}`,
    return_temperature: `prgHP.rReturnTemp_${systemIndex + 1}`,
    slider_manual: '',
    slider_power: `prgHP.byTimeEnabledPwr_${systemIndex + 1}`,
    state_emergency: `prgHP.bSOnEmergPowerOff_${systemIndex + 1}`,
    state_enable: `prgHP.bSEnabled_${systemIndex + 1}`,
    state_heating_pump: '',
    state_manual: '',
    state_reset: `prgHP.bSReset_${systemIndex + 1}`,
    state_time: `prgHP.bSTimeEnabled_${systemIndex + 1}`,
    state_time_power: `prgHP.bySTimeEnabledPwr_${systemIndex + 1}`,
    switch_emergency: `prgHP.bOnEmergPowerOff_${systemIndex + 1}`,
    switch_enable: `prgHP.bEnable_${systemIndex + 1}`,
    switch_manual: '',
    switch_reset: `prgHP.bReset_${systemIndex + 1}`,
    switch_time: `prgHP.bTimeEnabled_${systemIndex + 1}`,
    target_power: `prgHP.rTargetPower_${systemIndex + 1}`,
    title: systemName,
  };
}

export function otherBigConsumerMappingsEMS(systemIndex: number, systemName: string) {
  return {
    disable_soc: `prgOBC.fbBC${systemIndex + 1}.stSetupOBC.byDisableSOC`,
    enable_soc: `prgOBC.fbBC${systemIndex + 1}.stSetupOBC.byEnableSOC`,
    energy_counter: '',
    error: '',
    max_power: `prgOBC.fbBC${systemIndex + 1}.stSetupOBC.rMaxPower`,
    power: '',
    priority: `prgOBC.fbBC${systemIndex + 1}.stSetupOBC.byPriority`,
    slider_manual: '',
    slider_target_power: '',
    state_consumer: '',
    state_emergency: `prgBC.bSOnEmergPowerOff_${systemIndex + 1}`,
    state_enable: `prgOBC.fbBC${systemIndex + 1}.stDataOBC.bEnabled`,
    state_manual: '',
    switch_emergency: `prgBC.bOnEmergPowerOff_${systemIndex + 1}`,
    switch_enable: `prgOBC.fbBC${systemIndex + 1}.bEnable`,
    switch_manual: '',
    target_power: `prgOBC.fbBC${systemIndex + 1}.stDataOBC.rTargetPower`,
    title: `${systemName} ${systemIndex + 1}`,
  };
}

export function generatorMappingsEMS(systemName: string) {
  return {
    disable_soc: 'prgGEN.fbGen.stSetupGenerator.byDisableSOC',
    enable_soc: 'prgGEN.fbGen.stSetupGenerator.byEnableSOC',
    energy_counter: 'prgGEN.lrCounterE_Prod_Gen',
    error: '',
    power: 'prgGEN.lrPowerGen',
    state_enable: 'prgGEN.fbGen.stDataGenerator.bEnabled',
    state_generator: '',
    state_reset: 'prgGEN.fbGen.stDataGenerator.bSReset',
    switch_enable: 'prgGEN.fbGen.bEnable',
    switch_reset: 'prgGEN.fbGen.stSetupGenerator.bReset',
    hour_on: 'prgGEN.fbGen.stSetupGenerator.byHourOn',
    minute_on: 'prgGEN.fbGen.stSetupGenerator.byMinutesOn',
    hour_off: 'prgGEN.fbGen.stSetupGenerator.byHourOff',
    minute_off: 'prgGEN.fbGen.stSetupGenerator.byMinutesOff',
    enable_timelock: 'prgGEN.fbGen.stSetupGenerator.bLockTime',
    state_timelock: 'prgGEN.fbGen.stDataGenerator.bSLockTime',
    title: systemName,
  };
}

// CHP EMS Mappings
export function chpMappingsEMS(systemIndex: number) {
  return {
    buffer_storage_capacity: `prgBHKW.fbBHKW.stSetupBHKW_${systemIndex + 1}.rMaxCapHeatStorage`,
    buffer_temperature_bottom: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.rStorageTempBottom`,
    buffer_temperature_top: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.rStorageTempTop`,
    disable_soc: `prgBHKW.fbBHKW.stSetupBHKW_${systemIndex + 1}.byDisableSOC`,
    enable_soc: `prgBHKW.fbBHKW.stSetupBHKW_${systemIndex + 1}.byEnableSOC`,
    energy_counter: `prgBHKW.lrCounterE_Prod_${systemIndex + 1}_BHKW`,
    energy_counter_thermal: `prgBHKW.lrCounterT_Prod_${systemIndex + 1}_BHKW`,
    error: `prgBHKW.byErrorBHKW${systemIndex + 1}`,
    flow_temperature: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.rFlowTemp`,
    operation: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.bBHKWIsRunning`,
    parallel_grid_mode: `prgBHKW.fbBHKW.stSetupBHKW_${systemIndex + 1}.byWorkModeOn`,
    parallel_grid_target_power: `prgBHKW.fbBHKW.stSetupBHKW_${systemIndex + 1}.byTargetPowerOn`,
    power: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.lrElPower`,
    return_temperature: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.rReturnTemp`,
    state_activate_cooling: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.bSActEmergencyC`,
    state_activate_heating: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.bSActHeatingSys`,
    state_enable: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.bEnabled`,
    status: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.bOutput`,
    switch_activate_cooling: `prgBHKW.fbBHKW.stSetupBHKW_${systemIndex + 1}.bEmergencyCooler`,
    switch_activate_heating: `prgBHKW.fbBHKW.stSetupBHKW_${systemIndex + 1}.bEnHeatingSystem`,
    switch_enable: 'prgBHKW.fbBHKW.bEnable',
    target_power: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.rTargetPower`,
    thermal_power_input: `prgBHKW.fbBHKW.stSetupBHKW_${systemIndex + 1}.rThermalPowerBHKW`,
    thermal_power_output: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.lrThermalPower`,
    title: `${i18n.t('installationWizard.defineComponents.componentsPage.systems.chp.title')} ${systemIndex + 1}`,
  };
}

// EnergyView Functions
export function pvMappingEnergyView(systemIndex: number) {
  return {
    energy_counter: 'prgEnergy.lrCounterProdPV',
    error: '',
    power: 'prgEnergy.lrPVPower',
    title: 'PV Total',
  };
}

export function pvMappingEnergyViewDeye(systemIndex: number, isAcPv: boolean, inverterIndex = 1) {
  return {
    power: !isAcPv ? `prgPVOutData.rPower_${inverterIndex}_${systemIndex}` : 'prgEnergy.lrACPVPower',
    error: '',
    title: !isAcPv ? `MPPT${systemIndex} Inverter ${inverterIndex}` : 'PV AC Total',
    energy_counter: !isAcPv ? `prgPVOutData.lrCounterE_Prod_${inverterIndex}_${systemIndex}` : 'prgEnergy.lrCounterProdACPV',
  };
}

export function generatorMappingEnergyView(systemIndex: number) {
  return {
    energy_counter: 'prgGEN.lrCounterE_Prod_Gen',
    error: '',
    power: 'prgGEN.lrPowerGen',
    title: 'Generator',
  };
}

export function batteryMappingEnergyView(systemIndex: number) {
  return {
    energy_counter: 'prgEnergy.lrCounterProdBatt',
    reverse_energy_counter: 'prgEnergy.lrCounterConsBatt',
    error: '',
    power: 'prgEnergy.fbI.stDataBattery.lrPower',
    soc: 'prgEnergy.fbI.stDataBattery.dwSOC',
    title: i18n.t('installationWizard.defaultMappings.systemNames.battery').toString(),
  };
}

export function multiBatteryMappingEnergyView(systemIndex: number, batteryCount: number) {
  const { componentIndex, inverterIndex } = defineSystemIndex(systemIndex, batteryCount);
  return {
    energy_counter: `prgBattOutData.lrCounterE_Prod_${inverterIndex}_${componentIndex}`,
    reverse_energy_counter: `prgBattOutData.lrCounterE_Cons_${inverterIndex}_${componentIndex}`,
    error: '',
    power: `prgBattOutData.rPower_${inverterIndex}_${componentIndex}`,
    soc: `prgBattOutData.wSOC_${inverterIndex}_${componentIndex}`,
    title: `${i18n.t('installationWizard.defaultMappings.systemNames.battery')} ${componentIndex} Inverter ${inverterIndex}`,
  };
}

export function newHeatingPumpMappingsEnergyView(systemIndex: number, systemName: string) {
  return {
    title: `${systemName}`,
    power: `prgHP.lrPowerHP_${systemIndex + 1}`,
    energy_counter: `prgHP.lrCounterEl_HP_${systemIndex + 1}`,
    external_energy_measurement: false,
  };
}

export function newChargeStationMappingEnergyView(systemIndex: number, systemType = 'Webasto') {
  return {
    energy_counter: `prgCS.lrCounterCS_${systemIndex + 1}`,
    error: '',
    power: `prgCS.lrPowerCS_${systemIndex + 1}`,
    title: `${i18n.t('installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.charge_station')} ${systemIndex + 1}: ${systemType}`,
    external_energy_measurement: false,
    calculate_as_external_device: false,
  };
}

export function gridMappingEnergyView() {
  return {
    energy_counter: 'prgEnergy.lrCounterProdGrid',
    reverse_energy_counter: 'prgEnergy.lrCounterConsGrid',
    error: '',
    power: 'prgEnergy.fbGrid.stDataGrid.lrPower',
    title: i18n.t('installationWizard.defaultMappings.systemNames.grid').toString(),
    is_on_grid: 'prgEnergy.fbI.bOffGridModeActive',
  };
}

export function houseMappingEnergyView(systemIndex: number) {
  return {
    energy_counter: 'prgEnergy.lrCounterConsHouse',
    error: '',
    power: 'prgEnergy.lrHousePower',
    title: i18n.t('installationWizard.defaultMappings.systemNames.house').toString(),
  };
}

export function chargeStationMappingEnergyView(systemIndex: number, systemType = 'Webasto') {
  return {
    energy_counter: `prgCS.lrCounterCS_${systemIndex + 1}`,
    error: '',
    power: `prgCS.lrPowerCS_${systemIndex + 1}`,
    title: `${systemType}`,
    external_energy_measurement: false,
    calculate_as_external_device: false,
  };
}

export function electricHeatingMappingEnergyView(systemIndex: number, systemType = 'Askoma') {
  return {
    energy_counter: `prgEHE.lrCounterCons_${systemIndex + 1}`,
    error: '',
    power: `prgEHE.lrPower_${systemIndex + 1}`,
    title: `${systemType} ${systemIndex + 1}`,
    external_energy_measurement: false,
  };
}

export function otherBigConsumersEnergyView(systemIndex: number, systemName: string, pilotLine: number) {
  return {
    title: `${systemName} ${systemIndex + 1}`,
    power: `prgPilot.lrPower_${systemIndex + 1}_${pilotLine + 1}`,
    energy_counter: `prgPilot.lrCounter_Cons_${systemIndex + 1}_${pilotLine + 1}`,
    external_energy_measurement: true,
  };
}

// CHP EnergyView Functions
export function chpMappingsEnergyView(systemIndex: number) {
  return {
    energy_counter: `prgBHKW.lrCounterE_Prod_${systemIndex + 1}_BHKW`,
    error: `prgBHKW.byErrorBHKW${systemIndex + 1}`,
    power: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.lrElPower`,
    thermal_power_output: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.lrThermalPower`,
    flow_temperature: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.rFlowTemp`,
    return_temperature: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.rReturnTemp`,
    buffer_temperature_bottom: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.rStorageTempBottom`,
    buffer_temperature_top: `prgBHKW.fbBHKW.stDataBHKWOut${systemIndex + 1}.rStorageTempTop`,
    energy_counter_thermal: `prgBHKW.lrCounterT_Prod_${systemIndex + 1}_BHKW`,
    title: `${i18n.t('installationWizard.defineComponents.componentsPage.systems.chp.title')} ${systemIndex + 1}`,
  };
}

// function for Dummy Components mapping (External Energy Visualisation)

export function dummyComponent(systemName: string, pilotLineIndex: number, pilotSystemIndex: number) {
  // prgPilot.wCT_1 for charge station
  return {
    title: `${systemName}`,
    power: `prgPilot.lrPower_${pilotSystemIndex}_${pilotLineIndex}`,
    energy_counter: `prgPilot.lrCounter_Cons_${pilotSystemIndex}_${pilotLineIndex}`,
    external_energy_measurement: true,
  };
}

// Charging Station Device V2

export function chargeStationV2Mapping(systemIndex: number, selectedUsers: string[], powerMapping: string, systemType: string) {
  return {
    'mappings': {
      'OutputField_actualValue': powerMapping,
      'ShowEventDot_errorWarningState': `prgCS.bCarConected_${systemIndex + 1}`,
      'ShowEvent_errorWarningState': '',
      'Switch2V_onOff': `prgCS.bUseNotFreeCharge_${systemIndex + 1}`,
      'Switch2V_state': `prgCS.bSUseNotFreeCharge_${systemIndex + 1}`,
    },
    'meta': {
      'chargeID': `prgCS.byStartSession_${systemIndex + 1}`,
      'deviceSchema': {
        'additionalBasicDevices': [
          'Switch',
        ],
        'basicDevices': [
          'OutputField',
          'ShowEventDot',
        ],
      },
      'minMaxBorders': {
        'OutputField_actualValue': {
          'max': 100,
          'min': 0,
        },
      },
      'selectedUsers': selectedUsers,
      'systemType': systemType,
    },
  };
}
