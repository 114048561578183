
import { Component } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { calculateMinPowerPercent, checkUnsortedArrayEqual, minMaxValidation } from '@/utils/utilsFunctions';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { IProject } from '@/types/project.types';
import {
  getChargeStationPower,
} from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { ChargeModeOptionsKeys } from '@/ui/components/devices/devices/base/ChargeStationMinPowerSelection.vue';
import { chargeStationV2Mapping } from '@/ui/components/wizards/installationWizard/wizardSettings/defaultMappings';
import _ from 'lodash';
import { IDevice } from '@/types/devices.types';
import {
  IChargeStationSystem,
  IIncludedSystemsChargeStationDefinition,
  IMQTTVariable,
  ISystemsDefinitionsProps,
  NavigationDirection,
} from '@/types/wizards/installationWizard.types';
import { IMember } from '@/types/members.types';
import {
  plcVersionDate,
  chargingStationV2Feature,
  newChargeStationLimitDate, hybridVersionDate,
} from '@/utils/versionManagementUtils';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';
import { UserRoleCode } from '@/utils/userRoles';

@Component({
  computed: {
    ChargeModeOptionsKeys() {
      return ChargeModeOptionsKeys;
    },
  },
  methods: { minMaxValidation },
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class ChargingStationGridSettings extends WizardComponent {
  @Getter('members/members') members!: IMember[];
  @Getter('devices/allDevices') allDevices!: IDevice[];
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('installationWizard/navigationDirection') navigationDirection!: NavigationDirection;
  @Mutation('installationWizard/resetSystemsPropInIncludedSystemTypes') resetSystemsPropInIncludedSystemTypes!: (systemType: 'battery' | 'electric_heating' | 'charge_station' | 'heating_pump') => void;
  @Mutation('installationWizard/addSystemPropertyValues') addSystemPropertyValues!: (data: {
    systemType: string;
    data: any;
  }) => void;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystemSystemsProps') handleIncludedSystemsTypesSystemSystemsProps!: (
    { systemName, systemIndex, prop, value }: {
      systemName: string;
      systemIndex: number;
      prop: string;
      value: any;
    }
  ) => void;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => void;
  @Action('devices/createDevice') createDevice!: (control: IDevice) => Promise<IDevice>;
  @Action('devices/deleteDevice') deleteDevice!: (device: IDevice) => void;
  @Action('members/updateMemberCollections') updateMemberCollections!: (
    { project_id, member, collectionsList }: { project_id: string; member: IMember; collectionsList: string[] }
  ) => Promise<void>;

  doesHover = false;
  valid = false;
  settings: any = {};
  chargeStationIndices: any[] = [];
  minimumCharge: any[] = [];

  /* Charging Station V2 changes */
  assignedUsers: {id: string; users: string[]}[] = [];

  get showChargeStationV2Feature() {
    return plcVersionDate(this.project).getTime() > chargingStationV2Feature.getTime();
  }

  /**
   * Handle assigned users change
   */
  assignedUsersChange(event: any, key: number) {
    event.forEach((element: string | {value: string; text: string}, index: number) => {
      if (typeof element !== 'string') {
        // replace object with value
        this.assignedUsers[key].users[index] = element.value;
      }
    });
  }

  /**
   * Handle delete user
   */
  handleDeleteUser(item: string, index: number) {
    this.assignedUsers[index].users = this.assignedUsers[index].users.filter((user: string) => user !== item) ?? [];
  }

  /**
   * Returns array of electric charging station devices from all devices
   */
  get electricChargingStationV2Devices() {
    return this.allDevices.filter((device: IDevice) => device.data.type === 'ElectricChargingStationV2');
  }

  /**
   * Get charge station by index if it exists inside the project
   */
  chargeStationByIndex(index: number) {
    const chargingStations = this.electricChargingStationV2Devices;
    return chargingStations.find((device: IDevice) => device.data.mappings.OutputField_actualValue.includes(`prgCS.lrPowerCS_${index + 1}`) || device.data.mappings.OutputField_actualValue.includes(`prgPilot.lrPower_${index + 1}`));
  }

  /**
   * Get charge station by id
   */
  chargeStationById(id: string) {
    return this.electricChargingStationV2Devices.find((device: IDevice) => device.id === id);
  }

  /**
   * List of members for user selection
   */
  get selectableMembers() {
    return this.members.map((member: IMember) => ({
      value: member.id,
      text: `${member.first_name} ${member.last_name}`,
    }));
  }

  /**
   * Get member name by id
   */
  memberNameById(id: string) {
    const member = this.members.find((m: IMember) => m.id === id);
    return member ? `${member.first_name} ${member.last_name}` : '';
  }

  /**
   * Creates or updates electric charging station devices V2 inside the project
   */
  async updateProjectChargeStations() {
    const chargeStationComponents = this.emsDevice.data.meta.controllerMappings.charge_station.components;
    this.assignedUsers.forEach((element: {id: string; users: string[]}, index: number) => {
      const chargeStationType = this.includedSystemsChargeStationDefinitions[index].systemType;
      if (element.id?.length === 0 || element.id === undefined) {
        // create charge station device
        const newChargeStationMapping = chargeStationV2Mapping(
          index,
          element.users,
          chargeStationComponents[`charge_station${index + 1}`].power,
          chargeStationType,
        );
        const collectionId = this.chargeStationByIndex(0)?.collection_id ?? this.emsDevice.collection_id;
        const newDeviceObject: any = {
          data: {
            ...newChargeStationMapping,
            type: 'ElectricChargingStationV2',
          },
          name: `Ladestation ${index + 1}`,
          collection_id: collectionId,
          favorite: false,
        };
        this.createDevice(newDeviceObject);
      } else {
        // get chargeStation by id
        const chargeStation = this.chargeStationById(element.id);
        if (chargeStation) {
          chargeStation.data.meta.systemType = chargeStationType;
          chargeStation.data.meta.selectedUsers = element.users;
          // if chargeStation exists, update the selected users
          this.updateDevice({ device: chargeStation, skipReport: false });
        }
      }
    });
  }

  /**
   * Returns array of all selected users for charging stations
   */
  get allSelectedUsers() {
    const getAllSelectedUsers: any = [];
    this.electricChargingStationV2Devices.forEach((device: IDevice) => {
      device.data.meta.selectedUsers.forEach((user: any) => {
        if (getAllSelectedUsers.indexOf(user) === -1) {
          getAllSelectedUsers.push(user);
        }
      });
    });
    return getAllSelectedUsers ?? [];
  }

  async updateUserAccess() {
    if (this.electricChargingStationV2Devices.length === 0) return;
    const collectionIdToCheck = this.electricChargingStationV2Devices[0].collection_id;
    // check if all users that are selected for the charging station have access to the area or are admin users
    await this.allSelectedUsers.forEach(async (selectedUserForChargeStation: string) => {
      const memberById = this.members.find((member: IMember) => member.id === selectedUserForChargeStation);
      if (!memberById) return;
      if (memberById.role !== UserRoleCode.admin) {
        const collectionsForUser = memberById.collections;
        if (!collectionsForUser) return;
        if (!collectionsForUser.includes(collectionIdToCheck)) {
          // if user does not have access to the collection we add the collection to the user
          collectionsForUser.push(collectionIdToCheck);
          if (!this.project.id) return;
          await this.updateMemberCollections({
            project_id: this.project.id,
            member: memberById,
            collectionsList: collectionsForUser,
          });
        }
      }
    });
  }

  /*
   * Delete charge station devices V2 (if there are any that are not needed anymore)
   */
  async deleteChargeStationDevices() {
    const amountOfChargeStationsEMS = this.chargeStationIndices.length;
    const amountOfChargeStationsInProject = this.electricChargingStationV2Devices.length;

    // if there are more charge stations inside the project than in the EMS, delete the ones that are not needed
    if (amountOfChargeStationsInProject > amountOfChargeStationsEMS) {
      for (let index = amountOfChargeStationsEMS; index < amountOfChargeStationsInProject; index++) {
        // delete charge station device by index of mappings
        const chargeStation = this.chargeStationByIndex(index);
        if (chargeStation && index !== 0) {
          // delete charge station device if index is not 0 because one is needed for reference
          this.deleteDevice(chargeStation);
        }
      }
    }
  }

  /**
   * Checks if free charge is disabled
   */
  getFreeChargeStateByIndex(index: number) {
    const state: any = this.includedSystems.charge_station.definition[index].noFreeLoading;
    return state === 1;
  }

  // end of Charging Station V2 changes

  currentOption(systemIndex: number) {
    const option = _.cloneDeep(this.selectOptions.find((opt: any) => opt.key === this.minimumCharge[systemIndex].minimum_power_selection));
    if (option) option.value = this.minimumCharge[systemIndex].current;
    return option;
  }

  get selectOptions() {
    return [
      {
        key: ChargeModeOptionsKeys.full,
        value: 100,
        text: this.$t('mlModel.EMS.systems.charge_station.settings.fullCharge'),
      },
      {
        key: ChargeModeOptionsKeys.sun,
        value: 0,
        text: this.$t('mlModel.EMS.systems.charge_station.settings.sunCharge'),
      },
      {
        key: ChargeModeOptionsKeys.minimum,
        value: 0,
        text: this.$t('mlModel.EMS.systems.charge_station.settings.minimumCharge'),
        component: 'VTextField',
      },
    ];
  }

  get includedSystemsChargeStationSystems(): IChargeStationSystem[] {
    return this.includedSystems.charge_station.systems;
  }

  get includedSystemsChargeStationDefinitions(): IIncludedSystemsChargeStationDefinition[] {
    return this.includedSystems.charge_station.definition;
  }

  get inputRules() {
    const min = 6;
    const max = 32;
    return [this.fieldMoreThan(min), this.fieldLessThan(max)];
  }

  async handleChargeStationField({ key, value, index }: { key: string; value: any; index: any }) {
    this.handleIncludedSystemsTypesSystemSystemsProps({
      systemName: 'charge_station', systemIndex: index, prop: key, value,
    });

    await this.$nextTick();
    (this.$refs.form as any).validate();
  }

  async handleChargeModeSelection({ key, option, index }: { key: string; option: any; index: any }, replaceValue = false) {
    const chargeStationMapping = this.emsDevice.data.meta.controllerMappings.charge_station.components[`charge_station${index + 1}`];
    const maxPower = getChargeStationPower(this.includedSystems.charge_station.definition[index].systemType);
    this.handleIncludedSystemsTypesSystemSystemsProps({
      systemName: 'charge_station', systemIndex: index, prop: key, value: calculateMinPowerPercent(option.value, maxPower),
    });
    this.minimumCharge[index].minimum_power_selection = option.key;
    if (option.key === 'minimum' && replaceValue) {
      // if min power selected, set input value to current value saved in the EMS object or set to 0
      this.minimumCharge[index].current = chargeStationMapping.current ?? option.value;
    }
    await this.$nextTick();
    (this.$refs.form as any).validate();
  }

  setVariablesToSend(variableName: string, value: number | string = 0, systemIndex = 0, page = '') {
    this.variablesToSend.set(variableName + systemIndex, installationWizardVariables(variableName, 'componentsPage', value, systemIndex));
  }

  async prepareVariablesToSend() {
    const emsCopy = { ...this.emsDevice };
    const chargeStations: ISystemsDefinitionsProps[] = this.includedSystemsChargeStationSystems
      .flatMap((system: IChargeStationSystem, inx: number) => {
        const maxPower = getChargeStationPower(this.includedSystems.charge_station.definition[inx].systemType);
        const { current, minimum_power_selection } = this.minimumCharge[inx];
        const systemVariablesList: [string, IMQTTVariable][] = Object.entries(system);

        // Update charge station power selection in ems
        const option = this.selectOptions.find((opt: any) => opt.key === minimum_power_selection)!;
        emsCopy.data.meta.controllerMappings.charge_station.components[`charge_station${inx + 1}`].minimum_power_selection = minimum_power_selection;
        emsCopy.data.meta.controllerMappings.charge_station.components[`charge_station${inx + 1}`].current = minimum_power_selection === ChargeModeOptionsKeys.minimum ? current : maxPower * (option.value) / 100;
        if (minimum_power_selection !== ChargeModeOptionsKeys.minimum) {
          system.min_power.value = maxPower * (option.value) / 100;
        }

        // Update charge station name in ems from included systems
        emsCopy.data.meta.controllerMappings.charge_station.components[`charge_station${inx + 1}`].title = this.includedSystemsChargeStationDefinitions[inx].title;
        return systemVariablesList
          .map(([key, el]: [string, IMQTTVariable]) => ({ ...el, systemsIndex: inx, key }));
      });

    // eslint-disable-next-line no-restricted-syntax
    for (const ind of this.chargeStationIndices) {
      if (this.isNewChargeStationPlcVersion) {
        const index = typeof ind !== 'number' ? parseInt(ind, 10) - 1 : ind - 1;
        this.setVariablesToSend('chargeStationEnable', 1, index);
      } else {
        const variableToSend = `prgCS.fbCS${ind}.bEnable`;
        this.variablesToSend.set(variableToSend, {
          variable: variableToSend,
          value: 1,
          feedbackVariable: `prgCS.fbCS${ind}.stDataECS.bEnabled`,
          isBoolean: true,
        });
      }
    }

    // call send function for every this.settings property in for loop in typescript
    // eslint-disable-next-line no-restricted-syntax
    for (const element of chargeStations) {
      let valueToSend = typeof element?.value === 'string' ? parseFloat(element.value) : element?.value;
      if (element?.key === 'min_power' && this.minimumCharge[element.systemsIndex].minimum_power_selection !== 'minimum') {
        valueToSend = this.selectOptions.find((opt: any) => opt.key === this.minimumCharge[element.systemsIndex].minimum_power_selection)!.value;
      }
      const variableNames: any = {
        min_power: 'chargeStationMinPower',
        priority: 'chargeStationPriority',
        enable_soc: 'chargeStationEnableSOC',
        disable_soc: 'chargeStationDisableSOC',
      };

      if (this.isNewChargeStationPlcVersion) {
        this.setVariablesToSend(variableNames[element.key as any], valueToSend, element.systemsIndex);
      } else {
        const variableToSend = element?.variable as string;
        this.variablesToSend.set(variableToSend, {
          variable: variableToSend,
          value: valueToSend,
          feedbackVariable: element.feedbackVariable,
        });
      }
    }

    if (this.emsDevice.id) await this.updateDevice({ device: emsCopy, skipReport: false });

    if (this.showChargeStationV2Feature) {
      await this.updateProjectChargeStations();
      await this.deleteChargeStationDevices();
      await this.updateUserAccess();
    }
  }

  get isNewChargeStationPlcVersion() {
    return (plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime()) && (this.isDeye || this.isSolarmax);
  }

  get batteryDefinitionEmpty() {
    return this.emsDevice.data.meta.controllerMappings.battery.components.count === 0;
  }

  getVariableValuesFromMeasurements() {
    this.includedSystems.charge_station.systems.forEach((value: IChargeStationSystem, index: number) => {
      this.minimumCharge[index].minimum_power_selection = this.emsDevice.data.meta.controllerMappings.charge_station.components[`charge_station${index + 1}`].minimum_power_selection ?? ChargeModeOptionsKeys.minimum;
      const currentValue = this.emsDevice.data.meta.controllerMappings.charge_station.components[`charge_station${index + 1}`].current ?? 0;
      const option = _.cloneDeep(this.selectOptions.find((opt: any) => opt.key === this.minimumCharge[index].minimum_power_selection)!);
      if (this.minimumCharge[index].minimum_power_selection === ChargeModeOptionsKeys.minimum) option.value = currentValue;
      this.handleChargeModeSelection({ key: 'min_power', option, index });
      const variableFromMeasurements = this.measurements[value.priority.variable];
      const notDefaultValue = value.priority.value !== variableFromMeasurements;
      this.handleChargeStationField({
        key: 'priority',
        value: notDefaultValue && variableFromMeasurements !== 0 ? variableFromMeasurements : value.priority.value,
        index,
      });
      this.minimumCharge[index].current = currentValue ?? 0;
    });
  }

  async created() {
    this.resetSystemsPropInIncludedSystemTypes('charge_station');
    this.chargeStationIndices = Object.values(this.emsDevice.data.meta.controllerMappings.charge_station.components)
      // eslint-disable-next-line no-nested-ternary,radix
      .map((value: any, index: number) => (value.error ? (index >= 9 ? (parseInt(value.error[value.error.length - 1]) + 10).toString() : value.error[value.error.length - 1]) : undefined))
      .filter((value: number | undefined) => value);

    this.chargeStationIndices.forEach((ind: number, index: number) => {
      const defaultValues = {
        min_power: {
          value: 0,
          variable: this.isNewChargeStationPlcVersion ? `prgCS.rMinPower_${ind}` : `prgCS.fbCS${ind}.stSetupECS.byCSMinPower`,
          feedbackVariable: this.isNewChargeStationPlcVersion ? `prgCS.bySMinPower_${ind}` : `prgCS.fbCS${ind}.stDataECS.bySCSMinPower`,
        },
        priority: {
          value: this.batteryDefinitionEmpty ? 1 : 2,
          variable: this.isNewChargeStationPlcVersion ? `prgCS.byPrio_${ind}` : `prgCS.fbCS${ind}.stSetupECS.byPriority`,
        },
        enable_soc: {
          value: 1,
          variable: this.isNewChargeStationPlcVersion ? `prgCS.byEnaSOC_${ind}` : `prgCS.fbCS${ind}.stSetupECS.byEnableSOC`,
        },
        disable_soc: {
          value: 0,
          variable: this.isNewChargeStationPlcVersion ? `prgCS.byDisabSOC_${ind}` : `prgCS.fbCS${ind}.stSetupECS.byDisableSOC`,
        },
      };
      if (!this.includedSystems.charge_station.systems[index]) {
        this.addSystemPropertyValues({ systemType: 'charge_station', data: defaultValues });
      }
      this.minimumCharge.push({
        minimum_power_selection: ChargeModeOptionsKeys.minimum,
        current: 0,
      });
      this.handleChargeModeSelection({ key: 'min_power', option: this.selectOptions[2], index });

      if (this.showChargeStationV2Feature) {
        const id = this.chargeStationByIndex(index)?.id!;
        this.assignedUsers.push({ id, users: this.chargeStationByIndex(index)?.data.meta.selectedUsers ?? [] });
      } else {
        this.assignedUsers.push({ id: '', users: [] });
      }
    });

    if (this.wasInstallationWizardCompleted || this.navigationDirection === 0) {
      this.getVariableValuesFromMeasurements();
    }
  }
}
