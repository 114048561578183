
import { Component, Prop, Watch } from 'vue-property-decorator';
import { connectWithLineItems } from '@/utils/installationWizardUtilsFunctions';
import { Getter, Mutation } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import EnergyVisualisationPreview
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyVisualisationPreview.vue';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import { itemsContainValue } from '@/utils/utilsFunctions';
import {
  IIncludedSystemsChargeStationDefinition,
  IIncludedSystemsTypes
} from '@/types/wizards/installationWizard.types';
import {
  newChargeStationLimitDate,
  plcVersionDate,
  chargingStationV2Feature, hybridVersionDate
} from '@/utils/versionManagementUtils';
import { IProject } from '@/types/project.types';
import {
  chargeStationTypes,
  getChargeStationTypeInformationBySystemType
} from '@/ui/components/wizards/loggerWizard/wizardSettings/systemTypes';
import {
  webastoChargeStationTypes,
} from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import {
  pilotLinesCount,
  limitWebastoChargeStations,
  limitWaidmullerChargeStations,
  chargeStationLimit
} from '../../../wizardSettings/wizardLimits';
import { SYSTEM_IP_RANGE_START } from '../../../wizardSettings/wizardConstants';

@Component({
  computed: {
    SYSTEM_IP_RANGE_START() {
      return SYSTEM_IP_RANGE_START;
    },
    webastoChargeStationTypes() {
      return webastoChargeStationTypes;
    },
  },
  methods: {itemsContainValue, getChargeStationTypeInformationBySystemType},
  components: {
    InfoTooltip,
    EnergyVisualisationPreview,
  },
})
export default class ChargeStationComponentsSetting extends mixins(Validation, BasicControl) {
  @Prop({default: {}}) includedSystemsTypes!: IIncludedSystemsTypes;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('installationWizard/connectedLines') connectedLines!: number[];
  @Getter('installationWizard/getDisablePilotDevices') getDisablePilotDevices!: boolean;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystemDefinitionProps') handleIncludedSystemsTypesSystemDefinitionProps!: (
    {systemName, systemIndex, prop, value}: any
  ) => void;
  rerenderKey = 0;
  valid = false;
  doesHover = false;
  localConnectWithLineItems = connectWithLineItems();

  @Watch('includedSystemsTypes.charge_station.definition', {deep: true})
  updateValidation() {
    this.$nextTick(() => {
      this.validate();
      this.rerenderKey++;
    });
  }

  filterConnectWithLine(systemType: any[]): any[] {
    return systemType.map((element: any) => {
      return typeof element.connectWithLine !== 'string' ? element.connectWithLine : undefined;
    }).filter(element => element !== undefined);
  }

  get showChargeStationV2Feature() {
    return plcVersionDate(this.project).getTime() > chargingStationV2Feature.getTime();
  }

  get hasDuplicateValue(): boolean | string {
    const heatingPumpArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.heating_pump.definition);
    const chargeStationArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.charge_station.definition);
    const bigConsumerArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.big_consumer.definition);

    const array = [heatingPumpArray, chargeStationArray, bigConsumerArray].flat();
    // Create an object to store the count of each value
    const count: any = {};

    // Iterate over each element in the array
    for (let i = 0; i < array.length; i++) {
      const value = array[i];

      // If the value is already in the count object, it's a duplicate
      if (count[value]) {
        return this.$t('installationWizard.defineComponents.componentsPage.duplicatePilotLineSelection').toString();
      }

      // Otherwise, add the value to the count object
      count[value] = 1;
    }

    // No duplicates found
    return true;
  }

  // checks if limit of Webasto Charge Stations is reached
  get isLimitOfWebastoStationsReached() {
    const arrayWebasto = this.includedSystemsTypes.charge_station.definition.filter((value: IIncludedSystemsChargeStationDefinition) => {
      return value.systemType.includes('Webasto11') || value.systemType.includes('Webasto22');
    });
    return arrayWebasto.length >= limitWebastoChargeStations;
  }

  get isNewChargeStationPlcVersion() {
    return (plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime()) && (this.isDeye || this.isSolarmax);
  }

  // checks if limit of Weidmüller Charge Stations is reached
  get isLimitOfWeidmuellerStationsReached() {
    if (this.isNewChargeStationPlcVersion) return false;
    const arrayWeidmueller = this.includedSystemsTypes.charge_station.definition.filter((value: IIncludedSystemsChargeStationDefinition) => {
      return value.systemType.includes('Weidmüller') && value.systemType.includes('external');
    });
    return arrayWeidmueller.length >= limitWaidmullerChargeStations;
  }

  // returns all system types that can be selected for the current project and state of allready selected charge stations
  get availableChargeStationTypes() {
    let filteredStationsByDate = chargeStationTypes.filter(systemTypeElement => systemTypeElement.availableFrom.getTime() < plcVersionDate(this.project).getTime());

    // update disabled parameter so user can't select to many charge stations of one type
    filteredStationsByDate = filteredStationsByDate.map(system => {
      if (this.isLimitOfWeidmuellerStationsReached && system.name.includes('Weidmüller') && system.value.includes('external')) {
        // if limit is reached Webasto Stations will be disabled to select
        system.disabled = true;
      }
      // if limit is reached Webasto Stations will be disabled to select
      if (system.name.includes('Webasto')) {
        system.disabled = plcVersionDate(this.project).getTime() < newChargeStationLimitDate.getTime() && this.isLimitOfWebastoStationsReached;
      }
      return system;
    });

    return filteredStationsByDate;
  }

  // updates IncludedSystemsDefinitions in Store
  async handleChange(system_index: number | string, prop: string, value: any) {
    const index = typeof system_index === 'string' ? parseInt(system_index, 10) : system_index;
    if (prop === 'systemType') this.handleClear(index);
    this.handleIncludedSystemsTypesSystemDefinitionProps({
      systemName: 'charge_station',
      systemIndex: index,
      prop,
      value
    });

    if (prop === 'systemType') {
      // if we have a charge station with external measurement but the pilot devices are blocked we just add noLine as connectWithLine value
      if (this.getDisablePilotDevices) {
        const chargeStationInformations = getChargeStationTypeInformationBySystemType(value);
        if (chargeStationInformations.isExternal) {
          this.includedSystemsTypes.charge_station.definition[system_index as number].connectWithLine = 'noLine';
        }
      }
      // if charge station get changed from Webasto to another type set the webastoType to 0
      if (value !== undefined && this.showChargeStationV2Feature) {
        const chargeStationInformations = getChargeStationTypeInformationBySystemType(value);
        if (chargeStationInformations.type !== 'Webasto') {
          this.includedSystemsTypes.charge_station.definition[system_index as number].webastoType = 0;
        }
      }
    }

    this.validate();
    this.rerenderKey++;
  }

  handleClear(index: number) {
    this.includedSystemsTypes.charge_station.definition[index].systemType = '';
    this.includedSystemsTypes.charge_station.definition[index].connectWithLine = '';
  }

  @Watch('connectedLines', {deep: true})
  updateLineItems() {
    this.localConnectWithLineItems = this.localConnectWithLineItems.map((item: any) => {
      item.disabled = this.connectedLines.includes(item.value);
      return item;
    });
  }

  validate() {
    (this.$refs.form as any).validate();
    this.$emit('validationStatus', {system: 'charge_station', status: this.valid});
  }

  mounted() {
    this.$nextTick(() => {
      this.validate();
    });
    this.updateLineItems();
  }
}
