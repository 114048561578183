
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Component, Watch } from 'vue-property-decorator';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { Mutation, Getter, Action } from 'vuex-class';
import { minMaxValidation } from '@/utils/utilsFunctions';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import {
  emsLimitsByType,
} from '@/ui/components/wizards/installationWizard/wizardSettings/wizardLimits';
import { IProject } from '@/types/project.types';
import {
  IElectricHeatingElementSystem,
  IIncludedSystemsElectricHeatingDefinition,
  ISystemsDefinitionsProps,
  IMQTTVariable,
} from '@/types/wizards/installationWizard.types';
import VueI18n, { TranslateResult } from 'vue-i18n';
import { IDevice } from '@/types/devices.types';
import {
  legionellaProtectionDate,
  newChargeStationLimitDate,
  plcVersionDate
} from '@/utils/versionManagementUtils';

@Component({
  methods: { minMaxValidation },
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class HeatingElementSettings extends WizardComponent {
  @Getter('projects/project') project!: IProject;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => void;
  @Mutation('installationWizard/resetSystemsPropInIncludedSystemTypes')
  resetSystemsPropInIncludedSystemTypes!: (
    systemType: 'battery' | 'electric_heating' | 'charge_station' | 'heating_pump',
  ) => void;
  @Mutation('installationWizard/addSystemPropertyValues') addSystemPropertyValues!: (data: {
    systemType: string;
    data: any;
  }) => void;

  @Mutation('installationWizard/handleIncludedSystemsTypesSystemSystemsProps')
  handleIncludedSystemsTypesSystemSystemsProps!: ({
    systemName,
    systemIndex,
    prop,
    value,
  }: {
    systemName: string;
    systemIndex: number;
    prop: string;
    value: any;
  }) => void;

  doesHover = false;
  valid = false;
  fieldRules: any = null;
  editableSettings: {
    property: keyof IElectricHeatingElementSystem;
    unit: string;
    min: number | null;
    max: number | null;
    selection?: {
      value: number;
      text: string | TranslateResult;
    }[];
  }[] = [
    { property: 'priority', unit: '', min: 1, max: 255 },
    { property: 'temperature_on', unit: '°C', min: 0, max: 120 },
    { property: 'temperature_off', unit: '°C', min: 0, max: 120 },
    { property: 'temperature_max', unit: '°C', min: 0, max: 120 },
  ];

  get legionellaSelection() {
    return [
      {
        value: 0,
        text: this.$t('installationWizard.defineEmsSettings.heatingElementSettings.legionella_protection.warmWaterStorage'),
      },
      {
        value: 1,
        text: this.$t('installationWizard.defineEmsSettings.heatingElementSettings.legionella_protection.heatingBuffer'),
      },
    ];
  }

  get isLegionellaProtectionEnabled() {
    return (this.isDeye || this.isSolarmax) && (plcVersionDate(this.project).getTime() > legionellaProtectionDate.getTime());
  }

  get includedSystemsElectricHeatingSystems(): IElectricHeatingElementSystem[] {
    return this.includedSystems.electric_heating.systems;
  }

  get includedSystemsElectricHeatingDefinitions(): IIncludedSystemsElectricHeatingDefinition[] {
    return this.includedSystems.electric_heating.definition;
  }

  async handleElectricHeatingField({ key, value, index }: { key: string; value: any; index: any }) {
    this.handleIncludedSystemsTypesSystemSystemsProps({
      systemName: 'electric_heating',
      systemIndex: index,
      prop: key,
      value,
    });

    await this.$nextTick();
    (this.$refs.form as any).validate();
  }

  // rest is handled automatically
  async prepareVariablesToSend() {
    const emsCopy = { ...this.emsDevice };
    const electricHeatings: ISystemsDefinitionsProps[] =
      this.includedSystemsElectricHeatingSystems.flatMap(
        (system: IElectricHeatingElementSystem, inx: number) => {
          const systemVariablesList: [string, IMQTTVariable][] = Object.entries(system);

          // Update charge station name in ems from included systems
          emsCopy.data.meta.controllerMappings.electric_heating.components[`electric_heating${inx + 1}`].title = this.includedSystemsElectricHeatingDefinitions[inx].title;
          return systemVariablesList.map(([key, el]: [string, IMQTTVariable]) => ({
            ...el,
            systemsIndex: inx,
            key,
          }));
        },
      );

    for (let i = 0; i < this.includedSystemsElectricHeatingDefinitions.length; i++) {
      const variableToSend = `prgEHE.fbEHE${i + 1}.bEnable`;
      this.variablesToSend.set(variableToSend, {
        variable: variableToSend,
        value: 1,
        feedbackVariable: `prgEHE.fbEHE${i + 1}.stDataEHE.bEnabled`,
        isBoolean: true,
      });
    }

    // Update ems to override title of system
    if (this.emsDevice.id) await this.updateDevice({ device: emsCopy, skipReport: false });

    // call send function for every this.settings property in for loop in typescript
    // eslint-disable-next-line no-restricted-syntax
    for (const element of electricHeatings) {
      if (element.key === 'legionella_protection' && (!this.isLegionellaProtectionEnabled)) {
        return;
      }
      const valueToSend =
        typeof element?.value === 'string' ? parseFloat(element.value) : element?.value;
      const variableToSend = element?.variable as string;
      this.variablesToSend.set(variableToSend, {
        variable: variableToSend,
        value: valueToSend,
        feedbackVariable: element.feedbackVariable,
      });
    }
  }

  async created() {
    // Only after date on which legionella protection was added
    if (this.isLegionellaProtectionEnabled) {
      this.editableSettings.push({
        property: 'legionella_protection',
        unit: '',
        selection: this.legionellaSelection,
        min: 0,
        max: 1,
      });
    }
    this.resetSystemsPropInIncludedSystemTypes('electric_heating');
    Object.keys(this.includedSystemsElectricHeatingDefinitions).forEach(
      (element: string, index: number) => {
        const defaultValues = {
          priority: {
            value: 3,
            variable: `prgEHE.fbEHE${index + 1}.stSetupEHE.byPriority`,
          },
          temperature_on: {
            value: 35,
            variable: `prgEHE.fbEHE${index + 1}.stSetupEHE.uiTargetTempOn`,
          },
          temperature_off: {
            value: 50,
            variable: `prgEHE.fbEHE${index + 1}.stSetupEHE.uiTargetTempOff`,
          },
          temperature_max: {
            value: 62,
            variable: `prgEHE.fbEHE${index + 1}.stSetupEHE.uiTargetTempMax`,
          },
          legionella_protection: {
            value: 1,
            variable: `prgEHE.bDisableLegionellaPr_${index + 1}`,
            feedbackVariable: `prgEHE.bSDisableLegionellaPr_${index + 1}`,
            isBoolean: true,
          },
          timeEnabled: {
            value: 0,
            variable: `prgEHE.fbEHE${index + 1}.stSetupEHE.bTimeEnabled`,
            feedbackVariable: `prgEHE.fbEHE${index + 1}.stDataEHE.bSTimeEnabled`,
            isBoolean: true,
          },
          enableSOC: {
            value: 1,
            variable: `prgEHE.fbEHE${index + 1}.stSetupEHE.byEnableSOC`,
          },
          disableSOC: {
            value: 0,
            variable: `prgEHE.fbEHE${index + 1}.stSetupEHE.byDisableSOC`,
          },
        };
        if (!this.includedSystems.electric_heating.systems[index]) {
          this.addSystemPropertyValues({ systemType: 'electric_heating', data: { ...defaultValues } });
        }
      },
    );
    this.fieldRules = {
      priority: [this.rules.required, this.rules.noCommas],
      temperature_on: [this.rules.required, this.rules.noCommas],
      temperature_off: [this.rules.required, this.rules.noCommas],
      temperature_max: [this.rules.required, this.rules.noCommas],
      legionella_protection: [this.rules.required],
    };
    if (this.wasInstallationWizardCompleted) {
      this.getVariableValuesFromMeasurements();
    }
  }

  getVariableValuesFromMeasurements() {
    const keyArray = this.editableSettings.map((el) => el.property);
    // loop over existing heating elements
    this.includedSystems.electric_heating.systems.forEach((system: IElectricHeatingElementSystem, index) => {
      // loop over all variables in heating element that the user can edit inside the ui
      Object.entries(system).forEach(([key, entry]: any) => {
        // get variable default value
        const defaultValue = entry.value;
        const currentMeasurentValue = this.measurements[entry.variable];
        if (currentMeasurentValue !== defaultValue && keyArray.includes(key) && currentMeasurentValue !== undefined) {
          this.handleIncludedSystemsTypesSystemSystemsProps({
            systemName: 'electric_heating',
            systemIndex: index,
            prop: key,
            value: (key === 'priority' && currentMeasurentValue === 0 ) ? defaultValue : currentMeasurentValue,
          });
        }
      });
    });
  }
}
