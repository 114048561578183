// Utils for version management inside project
import { IProject } from '@/types/project.types';

/**
 * @param project project object as IProject
 * @returns date of last update of plc or started_at if plcLastUpdate is not defined
 */
export function plcVersionDate(project: IProject) {
  const { started_at } = project;
  const { plcLastUpdate } = project.meta;
  let date;

  // If plcLastUpdate is not defined, return started_at
  if (!plcLastUpdate) {
    date = new Date(started_at);
  } else {
    date = new Date(plcLastUpdate);
  }

  return date;
}

// contains all ChargeStations that are limited by version
// (Used in InstallationWizard)
export const versionLimitedChargeStations = {
  weidmueller11kWInternal: new Date('2023-06-08'),
  weidmueller22kWInternal: new Date('2023-06-08'),
};

// limit display of project offline symbol
// Used in (AppBar)
export const displayProjectOfflineStatus = new Date('2024-03-14');

// limit display of installation wizard info popup
// Used in (InstallationWizardInfoPopup)
export const showInstallationWizardInfoPopup = new Date('2023-10-06');

// date on which legionella protection was added
export const legionellaProtectionDate = new Date('2024-03-20');

// date on which hybrid version was introduced. This Includes
// Master Slave on Deye Systems with up to 5 hybrid inverters
// String Inverters
// SN Check and Management
// Added new systems to System Test Page
// New Page for Wizard Path Definition
export const hybridVersionDate = new Date('2024-09-26');

/*
 Version from 22.05.2024
 - Added Charge Station V2
 - Added limit for 10 Charge Stations and 3 Heating Pumps
 - Tenant Wago Counters
 */
const versionDate22052024 = new Date('2024-05-22');
export const newChargeStationLimitDate = versionDate22052024;
export const chargingStationV2Feature = versionDate22052024;
export const tenantUpdateWagoCounters = versionDate22052024;
export const tenantAcBatteryUpdate = versionDate22052024;
