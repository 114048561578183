// Will contain all limits that the user when going through the installation wizard

import {
  plcVersionDate,
  newChargeStationLimitDate,
  hybridVersionDate
} from '@/utils/versionManagementUtils';
import store from '@/store';
import { IProject } from '@/types/project.types';
import { BatteryType } from './systemTypes';

const azzurroSystemLimits: Record<string, number> = {
  pv: 2,
  generator: 0,
  battery: 1,
  grid: 1,
  house: 1,
  charge_station: 4,
  electric_heating: 2,
  heating_pump: 0,
  big_consumer: 0,
};
const deyeSystemLimits = (project: IProject, isStandalone = false): Record<string, number> => {
  return {
    pv: 5,
    generator: 1,
    battery: project.meta.wizardSettings?.isHybrid && !isStandalone ? 2 : 1,
    grid: 1,
    house: 1,
    charge_station: plcVersionDate(project).getTime() > newChargeStationLimitDate.getTime() ? 10 : 4,
    electric_heating: 2,
    heating_pump_consumer: 1,
    heating_pump: 3,
    big_consumer: 2,
    emergencyPowerOperation: 1,
    avPv: 1,
    chp: plcVersionDate(project).getTime() > hybridVersionDate.getTime() ? 3 : 1,
  };
};

const solarmaxSystemLimits = (project: IProject): Record<string, number> => {
  return {
    pv: 3,
    generator: 1,
    battery: 1,
    grid: 1,
    house: 1,
    charge_station: 10,
    electric_heating: 2,
    heating_pump_consumer: 1,
    heating_pump: 3,
    big_consumer: 2,
    emergencyPowerOperation: 1,
    avPv: 1,
    chp: 1,
  };
};

export function emsLimitsByType(type: BatteryType, project: IProject) {
  return {
    [BatteryType.AZZURRO]: azzurroSystemLimits,
    [BatteryType.DEYE]: deyeSystemLimits(project),
    [BatteryType.STANDALONE]: deyeSystemLimits(project, true),
    [BatteryType.SOLARMAX]: solarmaxSystemLimits(project),
    [BatteryType.MobileBatteryDeye]: deyeSystemLimits(project),
  }[type];
}

// maximum amount of Pilot Systems inside Project
export const pilotCountLimit = 1;
export const pilotLinesCount = 4;
export const externalVisualisationLimit = 4;

// limits for charge station types
export const limitWebastoChargeStations = 2;
export const limitWaidmullerChargeStations = 2;

export const chargeStationLimit = 10;

export const pvStringLoggerLimit = 20;

export const limitStringInverter = 10;
